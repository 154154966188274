import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { Container } from "../../styles/HomeStyle";

import { cardsHome } from "../../data";

import img from "../../images/image-the-art.png";
import img2 from "../../images/detalhe-newton1 1.png";

const Home = () => {
  const [width, setWidth] = useState();
  useEffect(() => {
    setWidth(window.innerWidth);
    //console.log({ expanded, width });
  });
  const [expanded, setExpanded] = React.useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded();
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderBottom: `1px solid #000000`,
    "&:not(:last-child)": {},
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      // expandIcon={
      //   <ArrowForwardIosSharpIcon
      //     sx={{
      //       fontSize: "2.5rem",
      //       color: "#000000",
      //       transform: "rotate(90deg)",
      //     }}
      //   />
      // }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#e8fbff",
    // flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(-18 0deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));

  return (
    <>
      <Header />
      <Container>
        <title>Home</title>
        <div className="first-block">
          <h1>Welcome to BrighterHeads!</h1>
          <p>
            A single technology-based project for Web 3.0 and metaverse which
            starts
          </p>
          <span>
            {" "}
            with a 9k exclusive NFT collection on the Solana Blockchain.
          </span>
        </div>
        <div className="third-block">
          <div className="background-miniaturas"></div>
          <h1>
            Beyond the Collectibles... <span> Purpose, value and utility.</span>
          </h1>
          <div className="beyond">
            <p>
              BrighterHeads is the first and{" "}
              <strong>unique tech-educational</strong> project that will
              maintain alive some of the most brilliant minds of history and
              make their story, work, creations, and teachings{" "}
              <strong>
                inspire younger generations, with a contemporary approach. By
                guiding them to a learning and creation path,
              </strong>{" "}
              we want to foment more BrighterHeads in the world!
            </p>
            {/* <p>
              The project contemplates eight series of 1.500 NFT´s and continues
              with a technology-based roadmap focused on learning and social
              experiences as well as personal and creative development.
            </p>
            <p>
              The NFTs will generate financial gains for owners with the sales
              of digital t-shirts for avatars in the metaverse, and other items
              and products. Members will also have access to exclusive products.
            </p>
            <p>
              The project will move in a direction of a Learn-to-Earn economy
              developed by the community validation and involvement and other
              possible tech-educational solution that we are planning to share.
            </p> */}
            <div className="box-gray">
              <p className="torquesa">
                The project has a <strong>technology-based roadmap</strong> that
                considers the <strong>development</strong> of a brand connected
                with <strong>learning, personal and creative</strong> growth as
                well as <strong>social experiences</strong>
              </p>
              <p style={{ marginTop: -50 }} className="torquesa">
                in and out of the Metaverse.{" "}
                <strong>
                  The project and our community with a unique design, follows
                  the sequential move of Inspiration, learning, creation and
                  performing
                </strong>
                .{" "}
              </p>
              <p className="green">
                <strong>Short mind map structure</strong>
              </p>
              <ul className="ul">
                <li className="pink ul">
                  <strong>
                    Development and selling of Digital and physical
                    inspirational products considering the distribution of some
                    royalties to NFT´s owners.
                  </strong>
                </li>
                <li className="pink ul">
                  <strong>Community involvement and collaboration.</strong>
                </li>
                <li className="pink ul">
                  <strong>
                    Creation of a Learning, Teaching and Earning platform that
                    gathers knowledge and profits to everybody involved.
                  </strong>
                </li>
                <li className="pink ul">
                  <strong>
                    Learning, creation, and digital lab experiences as well as
                    social ones in the Metaverse.
                  </strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="cards">
            {cardsHome.map((cards) => (
              <img src={cards.img} />
            ))}
            <button>
              <p>
                See all brighterheads
                <br /> in the Galery...
              </p>
            </button>
          </div>
        </div>
        <div className="second-block">
          <div className="writing-text">
            <h1>Value for the NFT´s owners</h1>
            <p>
              <span className="yellow">
                The 9K BrighterHeads{" "}
                <satrobg>Non-Fungible Tokens (NFT´s) will</satrobg> be initially
                a membership-pass for a co-participation on{" "}
                <strong>
                  skins, digital wearables and selling of other products
                  development.
                </strong>
              </span>{" "}
              <strong>
                These digital items inspired by the NFT collection will be
                created for avatars
              </strong>{" "}
              in multiple Metaverses considering the significant growth of the
              digital fashion market. In a further moment of the project{" "}
              <span className="yellow">
                <strong>BrighterHeads members will also have:</strong>
              </span>
            </p>
            <ul>
              <strong>
                <li>
                  <sapn className="yellow">
                    Access to exclusive products and experiences;
                  </sapn>
                </li>
              </strong>
              <strong>
                <li>
                  <sapn className="yellow">
                    Priority on the application process to enter in the artistic
                    community club;
                  </sapn>
                </li>
              </strong>
              <strong>
                <li>
                  <sapn className="yellow">
                    Higher votes value in the community and access to more
                    exclusive decisions;
                  </sapn>
                </li>
              </strong>
              <strong>
                <li>
                  <sapn className="yellow">
                    Access to exclusive discord rooms;
                  </sapn>
                </li>
              </strong>
              <strong>
                {" "}
                <li>
                  {" "}
                  <sapn className="yellow">
                    Special conditions on the ICO launch.
                  </sapn>
                </li>
              </strong>
            </ul>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ borderBottom: "none" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ backgroundColor: "#fff" }}
              >
                <Typography className="font">
                  <button className="more">
                    {expanded === "panel1" ? "Less" : "More"}
                  </button>{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="more-text">
                  Every NFT brings a cartoon character with a T-shirts that
                  normally represents something related to, their thinking,
                  story or work. Those T-shirts and the full character clothes
                  will become digital items and skins for avatars in the
                  Metaverse. Once this operation is set, we are going to analyze
                  and determine a sustainable percentage of royalties’
                  distribution generated by the selling of these items, due the
                  co participation of members in the creative and development
                  process. Directly or indirectly choosing and validating design
                  options, colors, contents and other artistic characteristics.
                  We plan to use tokens and blockchain to guaranty the
                  transparency of this operation. The exclusive products and
                  experiences includes digital and physical items, talks and
                  master classes and exclusive conditions on our future
                  meteverse.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <h1>Made within the community</h1>
            <p>
              We believe that our purpose belongs to all, and we wish to involve
              the community in the development of our core business.
            </p>
            <p>
              The project will move in the direction of an immersion Lab,
              Learning and creating experiences development in the Metaverse and
              a Learn, Teach and Earn economy established and validated with the
              involvement of the community based on what we have already
              designed. We plan to share other tech-educational solution ideas
              in the future.{" "}
            </p>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ borderBottom: "none" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{ backgroundColor: "#fff" }}
              >
                <Typography className="font">
                  <button className="more">
                    {expanded === "panel2" ? "Less" : "More"}
                  </button>{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="more-text">
                  The introduction of technology in learning is one of the
                  challenges in the education sector. The most valued skills
                  today are not only related to knowledge. Curiosity,
                  initiative, persistence, resistance to frustration
                  adaptability, leadership and proactivity generate the value
                  required today. Some of the knowledge taught at the beginning
                  of a university education becomes obsolete by the time the
                  student completes the course. That's why we aim to create an
                  educational complementary solution focused on “learning to
                  learn”, for self-taught personal development problem solving
                  and creativity with the help of technology considering the
                  best that the immersive experience in the Metaverse can bring
                  to this specific field. Developing and validating those tools
                  withinthe community is the challenge that we are excited to
                  face
                </Typography>
              </AccordionDetails>
            </Accordion>
            <p>
              The community involvement will also be present in the artistic
              creative process considering that some Avatars skins, wearables,
              and physical clothes will be develop through a collab practice
              generating revenue to this artistic group.{" "}
            </p>
          </div>
          <div id="roadmap" className="writing">
            <h1>Roadmap</h1>
            <div className="writing-2">
              <div className="bots">
                <p className="green">
                  Our unique tech-based mind map is in tune with the web 3.0
                  trends, its market opportunities, and its innovative
                  possibilities in the educational field.
                </p>
                <p className="torquesa">
                  We are going to deliver 6 limited series of 1500 NFT
                  collectibles and a last one with 1000 NFT´s. As the membership
                  accession increases, we will achieve enough funds to start
                  working and developing some objectives. They are:
                </p>
                <span>
                  {/* <div className="bots-1">
                  </div> */}
                  <div className="bots-2">
                    <div
                      style={
                        expanded === "panel3" && width < 500
                          ? { height: 500 }
                          : expanded === "panel3"
                          ? { height: 205 }
                          : {}
                      }
                      className={"bar-progress"}
                    >
                      <p className="pink">
                        <span></span>After 20% of NFT members accession
                      </p>
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                        sx={{
                          borderBottom: "none",
                          backgroundColor: "transparent",
                          width: "100%",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ backgroundColor: "transparent" }}
                        >
                          <Typography className="roadmap-btn">
                            <p className="description">
                              Development and selling of all exclusive digital
                              T-Shirts, skins or wearables for avatars with
                              royalties’ distribution to the NFT owners and
                              raising funds to support younger researchers,
                              inventors and entrepreneurs.
                            </p>
                            <button className="more">
                              {expanded === "panel3" ? "Less" : "More"}
                            </button>{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={width < 500 ? { marginTop: 80 } : {}}
                            className="more-text-btn"
                          >
                            Every NFT brings a cartoon character with a T-shirts
                            that normally represents something related to, their
                            thinking, story or work. Those T-shirts and the full
                            character clothes will become digital items and
                            skins for avatars inthe Metaverse. Once this
                            operation is set, weare going to analyze and
                            determine a sustainable percentage of royalties’
                            distribution generated by the selling of these
                            items, due the co participation of members in the
                            creative and development process. We plan to use
                            tokens and blockchain to guaranty the transparency
                            of this operation.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      style={
                        expanded === "panel4" && width < 500
                          ? { height: 330 }
                          : expanded === "panel4"
                          ? { height: 200 }
                          : {}
                      }
                      className={"bar-progress"}
                    >
                      <p className="pink">
                        <span></span>After 30% of NFT members accession
                      </p>
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                        sx={{
                          borderBottom: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ backgroundColor: "transparent" }}
                        >
                          <Typography className="roadmap-btn">
                            <p className="description">
                              Launch of a creative community club rewarding
                              collaborative artists.
                            </p>
                            <button className="more">
                              {expanded === "panel4" ? "Less" : "More"}
                            </button>{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="more-text-btn">
                            We believe that our purpose belongs to all, and we
                            wish to involve more artists and designers in the
                            project, by launching a collab initiative. Our
                            creative community will be able to co-create
                            clothes, backgrounds and accessories for some future
                            BrighterHeads’ series and be financially rewarded by
                            their creations. Members will have priority on the
                            application process to enter in this artistic
                            community club.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      style={
                        expanded === "panel5" && width < 500
                          ? { height: 430 }
                          : expanded === "panel5"
                          ? { height: 200 }
                          : {}
                      }
                      className={"bar-progress"}
                    >
                      <p className="pink">
                        <span></span>After 40% of NFT members accession
                      </p>
                      <Accordion
                        expanded={expanded === "panel5"}
                        onChange={handleChange("panel5")}
                        sx={{
                          borderBottom: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ backgroundColor: "transparent" }}
                        >
                          <Typography className="roadmap-btn">
                            <p className="description">
                              Launch of collaborative digital space for sharing
                              our ideas discuss and validate concepts involving
                              the community in the project development.
                            </p>
                            <button className="more">
                              {expanded === "panel5" ? "Less" : "More"}
                            </button>{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={width < 500 ? { marginTop: 30 } : {}}
                            className="more-text-btn"
                          >
                            Our shared purpose deserves the involvement of the
                            community in the project and business development.
                            The community will have direct participation on the
                            choice of personalities for the new series. It will
                            also play an important role on the validation and
                            choice of ideas, functionalities, and solutions for
                            our Metaverse plans, our “Learn, to Earn” platform
                            model and other learning tech outcomes that can be
                            released at this time. Members will have higher
                            votes value and access to more exclusive decisions.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className={"bar-progress"}>
                      <p className="pink">
                        <span></span>After 50% of NFT members accession
                      </p>
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handleChange("panel6")}
                        sx={{
                          borderBottom: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ backgroundColor: "transparent" }}
                        >
                          <Typography className="roadmap-btn">
                            <p className="description">
                              Final scope definition of tech-platform for web
                              3.0 and product mix.{" "}
                            </p>
                            {/* <button className="more-btn">More</button>{" "} */}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="more-text-btn">...</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      style={
                        expanded === "panel8" && width < 500
                          ? { height: 250 }
                          : expanded === "panel8"
                          ? { height: 200 }
                          : {}
                      }
                      className={"bar-progress"}
                    >
                      <p className="pink">
                        <span></span>After 60% of NFT members accession
                      </p>
                      <Accordion
                        expanded={expanded === "panel8"}
                        onChange={handleChange("panel8")}
                        sx={{
                          borderBottom: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ backgroundColor: "transparent" }}
                        >
                          <Typography className="roadmap-btn">
                            <p className="description">
                              Development of physical products.
                            </p>
                            <button className="more">
                              {expanded === "panel8" ? "Less" : "More"}
                            </button>{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={width < 500 ? { marginTop: -60 } : {}}
                            className="more-text-btn"
                          >
                            Every NFT brings a cartoon character with a T-shirts
                            that normally represents something related to, their
                            thinking, story, or work. We plan to produce and
                            makethese T-shirtsavailable in our future
                            e-commerce. Stickers and other physical products
                            might also be developed.{" "}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className={"bar-progress"}>
                      <p className="pink">
                        <span></span>After 70% of NFT members accession
                      </p>

                      <p className="description">
                        Launch of e-commerce in our website.
                      </p>
                    </div>
                    <div
                      className={
                        expanded === "panel3"
                          ? "bar-progress-expanded"
                          : "bar-progress"
                      }
                    >
                      <p className="pink">
                        <span></span>After 80% of NFT members accession
                      </p>
                      <p className="description">
                        Start the development of our Lab, learning and creation
                        space in the Metaverse.
                      </p>
                    </div>
                    <div
                      style={
                        expanded === "panel9" && width < 500
                          ? { height: 200 }
                          : expanded === "panel9"
                          ? { height: 200 }
                          : {}
                      }
                      className={"bar-progress"}
                    >
                      <p className="pink">
                        <span></span>After 90% of NFT members accession
                      </p>
                      <Accordion
                        expanded={expanded === "panel9"}
                        onChange={handleChange("panel9")}
                        sx={{
                          borderBottom: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          sx={{ backgroundColor: "transparent" }}
                        >
                          <Typography className="roadmap-btn">
                            <p className="description">
                              Full work to deliver all tech solutions built
                              within the community.{" "}
                            </p>
                            <button className="more">
                              {expanded === "panel9" ? "Less" : "More"}
                            </button>{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="more-text-btn">
                            Members will have buying priority on the ICO launch.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className={"bar-progress"}>
                      <p className="pink">
                        <span></span>After 100% of NFT members accession
                      </p>
                      <p className="description">
                        This is not the end. After achieving and delivering
                        100%, we will set a new road map with our community
                        collaboration.{" "}
                      </p>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div
            style={expanded !== "" ? { marginTop: 50 } : {}}
            id="theart"
            className="text"
          >
            <h4>The art...</h4>
            <div className="text-1">
              <img src={width <= 500 ? img2 : img} />
              <div>
                <p>
                  The art project will be divided into six NFT´s series
                  totalizing 9k unique collectables and membership share-pass.
                  We have developed a{" "}
                  <strong>digital pointillism technique</strong> to reproduce
                  the face of some of the brightest personalities in history. In
                  the first series of 1500 NFT´s{" "}
                  <strong>
                    some heads had over 8.000 digitally handmade colored
                    objects.
                  </strong>
                </p>
                <p>
                  The drawings, pictures or texts used in the BrighterHeads
                  NFT´s T-Shirts bring some elements that built the thoughts,
                  work, contribution, or creations of the remarkable
                  BrighterHeads.
                </p>
                <p>
                  That work was the basis for a contemporary unique collection
                  of historical{" "}
                  <stropng>
                    characters full of life, colors and content.
                  </stropng>
                  The art series also seeks to inspire and motivate young
                  generations to put{" "}
                  <strong>
                    energy on learning, creation, and self-development.
                  </strong>
                </p>
              </div>
            </div>
            <h4 id="team">Team</h4>
            <div className="text-2">
              <div>
                <p>Founder: Gianfranco Cacciola </p>
                <p className="span yellow">
                  From digital arts and music to consumer goods. From infants’
                  stories and games to tech platforms, the founder is a
                  passionate multidisciplinary creator. He also dedicates some
                  time to photography and sports! Has a master’s degree in
                  marketing and has been running this project with energy and
                  passion!
                </p>
              </div>
              <div>
                <p>Head of IT: </p>
              </div>
              <div>
                <p>Marketing and administration:</p>
              </div>
              <div>
                <p>Community Manager:</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default Home;
